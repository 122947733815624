import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Review from "./Review";
import Create from "./Create";
import Change from "./Change";
import Game from "./Game";
import Old from "./Old";
import AXIOS from "../../Axios";

const LB = () => {
  const [ReviewPage, setReviewPage] = useState(true);
  const [CreatePage, setCreatePage] = useState(false);
  const [ChangePage, setChangePage] = useState(false);
  const [GamePage, setGamePage] = useState(false);
  const [OldPage, setOldPage] = useState(false);
  const [FCChange, setFCChange] = useState(null);
  const [Counter, setCounter] = useState(0);
  const [MoveFC, setMoveFC] = useState("");
  useEffect(() => {
    AXIOS.get(`/Counter.json`)
      .then((responseDay) => {
        if (responseDay.status === 200) {
          setCounter(responseDay.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <React.Fragment>
      <Box className="Wrapper">
        <Box className="LB">
          {ReviewPage ? (
            <Review
              setReviewPage={setReviewPage}
              setCreatePage={setCreatePage}
              setChangePage={setChangePage}
              setFCChange={setFCChange}
              Counter={Counter}
              setCounter={setCounter}
              setGamePage={setGamePage}
              setOldPage={setOldPage}
            />
          ) : null}
          {CreatePage ? (
            <Create
              setReviewPage={setReviewPage}
              setCreatePage={setCreatePage}
              MoveFC={MoveFC}
            />
          ) : null}
          {ChangePage ? (
            <Change
              FCChange={FCChange}
              setReviewPage={setReviewPage}
              setChangePage={setChangePage}
            />
          ) : null}
          {GamePage ? (
            <Game setReviewPage={setReviewPage} setGamePage={setGamePage} />
          ) : null}
          {OldPage ? (
            <Old
              setReviewPage={setReviewPage}
              setOldPage={setOldPage}
              setCreatePage={setCreatePage}
              setMoveFC={setMoveFC}
            />
          ) : null}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LB;
