import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import PolylineIcon from "@mui/icons-material/Polyline";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AXIOS from "../../Axios";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Grammer from "./Grammer";

const Review = (propse) => {
  const [OME1, setOME1] = useState(false);
  const [OME2, setOME2] = useState(false);
  const [Info, setInfo] = useState("");
  const [Server, setServer] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [LoadingSave, setLoadingSave] = useState(false);
  const [ShowStatement, setShowStatement] = useState(true);
  const [SaveCounterEnter, setSaveCounterEnter] = useState("");
  const [GrammerLInk, setGrammerLInk] = useState(false);
  const GetData = async () => {
    let dataArray = [];
    await AXIOS.get("/FINAL.json")
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          for (let X in response.data) {
            if (response.data[X].Status === 0) {
              dataArray.push(response.data[X]);
            }
          }
          setServer(dataArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    GetData();
  }, []);
  const HandlePolylineIcon = () => {
    propse.setReviewPage(false);
    propse.setCreatePage(true);
  };
  const HnadleLeft = () => {
    setShowStatement(true);
    if (Server === null || Server.length === 0) {
    } else {
      if (propse.Counter > 0) {
        propse.setCounter(propse.Counter - 1);
      }
    }
  };
  const HnadleRight = () => {
    setShowStatement(true);
    if (Server === null || Server.length === 0) {
    } else {
      if (propse.Counter < Server.length - 1) {
        propse.setCounter(propse.Counter + 1);
      }
    }
  };
  const HandleAddToGame = async () => {
    if (Server.length !== 0) {
      setLoadingSave(true);
      setShowStatement(true);
      const DATA = {
        ID: Server[propse.Counter].ID,
        Statement1: Server[propse.Counter].Statement1,
        Statement2: Server[propse.Counter].Statement2,
        Status: 1,
      };
      await AXIOS.put(`/FINAL/${Server[propse.Counter].ID}.json`, DATA)
        .then((response) => {
          if (response.status === 200) {
            if (propse.Counter === Server.length - 1 && Server.length !== 1)
              propse.setCounter(propse.Counter - 1);
            GetData();
            setLoadingSave(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const HandleChange = () => {
    if (Server.length !== 0) {
      propse.setFCChange(Server[propse.Counter]);
      propse.setReviewPage(false);
      propse.setChangePage(true);
    }
  };
  const HandleGame = () => {
    propse.setReviewPage(false);
    propse.setGamePage(true);
  };
  const HandleOld = () => {
    propse.setReviewPage(false);
    propse.setOldPage(true);
  };
  const SaveCounter = () => {
    AXIOS.put(`/Counter.json`, propse.Counter)
      .then((responseDay) => {
        if (responseDay.status === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {Loading ? (
        "Loading ..."
      ) : GrammerLInk ? (
        <Grammer />
      ) : (
        <React.Fragment>
          <Box
            className="ID"
            onMouseEnter={() => setSaveCounterEnter("green")}
            onMouseLeave={() => setSaveCounterEnter("")}
            onClick={SaveCounter}
            sx={{
              color: SaveCounterEnter,
              cursor: "pointer",
            }}
          >
            {Server === null || Server.length === 0 ? (
              <span className="Nothing">- Nothing -</span>
            ) : (
              `ID :${propse.Counter + 1}FROM${Server.length}`
            )}
          </Box>
          <Box className="Text">
            <Box
              className="Left"
              onMouseEnter={() => setOME1(true)}
              onMouseLeave={() => setOME1(false)}
              onClick={HnadleLeft}
            >
              <ArrowBackIosNewIcon sx={{ opacity: OME1 ? "0.2" : "0" }} />
            </Box>
            <Box
              className="Center"
              onClick={() => setShowStatement(!ShowStatement)}
              sx={{
                direction: ShowStatement ? "rtl" : "ltr",
                fontFamily: ShowStatement ? "FA-EN05" : "Outfit",
                fontSize: ShowStatement ? "0.85rem" : "0.95rem",
              }}
            >
              {Server === null || Server.length === 0 ? (
                <span className="Nothing">- Nothing -</span>
              ) : ShowStatement ? (
                // Server[propse.Counter].Statement2
                Server[propse.Counter].Statement2.split("\n").map(
                  (line, index) => <div key={index}>{line}</div>
                )
              ) : (
                // Server[propse.Counter].Statement1
                Server[propse.Counter].Statement1.split("\n").map(
                  (line, index) => <div key={index}>{line}</div>
                )
              )}
            </Box>
            <Box
              className="Right"
              onMouseEnter={() => setOME2(true)}
              onMouseLeave={() => setOME2(false)}
              onClick={HnadleRight}
            >
              <ArrowForwardIosIcon sx={{ opacity: OME2 ? "0.2" : "0" }} />
            </Box>
          </Box>
          <Box className="Extra">
            <Box>
              <PolylineIcon
                onMouseEnter={() => setInfo("Create FC")}
                onMouseLeave={() => setInfo("")}
                onClick={HandlePolylineIcon}
              />
              <SportsBasketballIcon
                onMouseEnter={() => setInfo("Playe FC")}
                onMouseLeave={() => setInfo("")}
                onClick={HandleGame}
              />
              <TrendingUpIcon
                onMouseEnter={() => setInfo("Change FC")}
                onMouseLeave={() => setInfo("")}
                onClick={HandleChange}
              />
              {LoadingSave ? (
                <span className="LoadingSave">...</span>
              ) : (
                <AttachFileIcon
                  onMouseEnter={() => setInfo("Add to Game")}
                  onMouseLeave={() => setInfo("")}
                  onClick={HandleAddToGame}
                />
              )}
              <AcUnitIcon
                onMouseEnter={() => setInfo("Old FC's")}
                onMouseLeave={() => setInfo("")}
                onClick={HandleOld}
              />
            </Box>
            <Box>{Info}</Box>
          </Box>
          <Box className="Grammer" onClick={() => setGrammerLInk(true)}>
            GRAMMER
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Review;
