import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudQueueOutlinedIcon from "@mui/icons-material/CloudQueueOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AXIOS from "../../Axios";

const Create = (propse) => {
  const [Info, setInfo] = useState("");
  const [Server, setServer] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Statement1, setStatement1] = useState("");
  const [Statement2, setStatement2] = useState("");
  const [Counter, setCounter] = useState(0);
  const GetData = async () => {
    await AXIOS.get("/FINAL.json")
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          if (response.data === null) setCounter(0);
          else setCounter(response.data.length);
          setServer(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (propse.MoveFC.length !== 0) setStatement2(propse.MoveFC);
    GetData();
  }, []);
  const HnadleBack = () => {
    propse.setReviewPage(true);
    propse.setCreatePage(false);
  };
  const DATA = {
    ID: Counter,
    Statement1: Statement1,
    Statement2: Statement2,
    Status: 0,
  };
  const onSubmit = async () => {
    if (Statement1.length !== 0 && Statement2.length !== 0) {
      await AXIOS.put(`/FINAL/${Counter}.json`, DATA)
        .then((response) => {
          if (response.status === 200) {
            setStatement1("");
            setStatement2("");
            setLoading(true);
            GetData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <React.Fragment>
      {Loading ? (
        "Loading ..."
      ) : (
        <React.Fragment>
          <Box className="ID">
            ID :
            {Server === null || Server.length === 0
              ? "0FROM0"
              : `${Counter}FROM${Counter}`}
          </Box>
          <Box className="Text_TextArea">
            <textarea
              id="Statement1"
              className="TextArea"
              style={{ direction: "ltr" }}
              value={Statement1}
              onChange={(e) => setStatement1(e.target.value)}
            ></textarea>
            <Box sx={{ color: "rgb(0, 0, 0,0.3)", fontFamily: "RA-EN04" }}>
              -----------------------------------
            </Box>
            <textarea
              id="Statement2"
              className="TextArea"
              style={{ direction: "rtl" }}
              value={Statement2}
              onChange={(e) => setStatement2(e.target.value)}
            ></textarea>
          </Box>
          <Box className="Extra">
            <Box>
              <ArrowBackIcon
                onMouseEnter={() => setInfo("Back to review")}
                onMouseLeave={() => setInfo("")}
                onClick={HnadleBack}
              />
              <CloudQueueOutlinedIcon
                onMouseEnter={() => setInfo("Create FC")}
                onMouseLeave={() => setInfo("")}
                onClick={onSubmit}
              />
            </Box>
            <Box>{Info}</Box>
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Create;
