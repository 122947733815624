import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import AXIOS from "../../Axios";

const Game = (propse) => {
  const [Loading, setLoading] = useState(true);
  const [Day, setDay] = useState([]);
  const [Counter, setCounter] = useState(0);
  const [Server, setServer] = useState([]);
  const [Flag, setFlag] = useState([]);
  const [Info, setInfo] = useState("");
  const [ShowStatement, setShowStatement] = useState(true);
  const HnadleBack = () => {
    propse.setReviewPage(true);
    propse.setGamePage(false);
  };
  const GetData = async () => {
    let dataArray = [];
    let dataUnFlow = [];
    await AXIOS.get("/DAYS.json")
      .then((responseDay) => {
        if (responseDay.status === 200) {
          AXIOS.get("/FINAL.json")
            .then((responseFinal) => {
              if (responseFinal.status === 200) {
                setDay(responseDay.data);
                for (let X in responseFinal.data) {
                  if (responseDay.data === 1 || responseDay.data === 2) {
                    if (responseFinal.data[X].Status === 1) {
                      dataArray.push(responseFinal.data[X]);
                    }
                  }
                  if (
                    responseDay.data === 3 ||
                    responseDay.data === 4 ||
                    responseDay.data === 5 ||
                    responseDay.data === 6
                  ) {
                    if (
                      responseFinal.data[X].Status === 1 ||
                      responseFinal.data[X].Status === 2
                    )
                      dataArray.push(responseFinal.data[X]);
                    if (responseFinal.data[X].Status === 3)
                      dataUnFlow.push(responseFinal.data[X]);
                  }
                  if (
                    responseDay.data === 7 ||
                    responseDay.data === 8 ||
                    responseDay.data === 9 ||
                    responseDay.data === 10 ||
                    responseDay.data === 11 ||
                    responseDay.data === 12 ||
                    responseDay.data === 13 ||
                    responseDay.data === 14
                  ) {
                    if (
                      responseFinal.data[X].Status === 1 ||
                      responseFinal.data[X].Status === 2 ||
                      responseFinal.data[X].Status === 4
                    )
                      dataArray.push(responseFinal.data[X]);
                    if (
                      responseFinal.data[X].Status === 3 ||
                      responseFinal.data[X].Status === 5 ||
                      responseFinal.data[X].Status === 6 ||
                      responseFinal.data[X].Status === 7
                    )
                      dataUnFlow.push(responseFinal.data[X]);
                  }
                  if (
                    responseDay.data === 15 ||
                    responseDay.data === 16 ||
                    responseDay.data === 17 ||
                    responseDay.data === 18 ||
                    responseDay.data === 19 ||
                    responseDay.data === 20 ||
                    responseDay.data === 21 ||
                    responseDay.data === 22 ||
                    responseDay.data === 23 ||
                    responseDay.data === 24 ||
                    responseDay.data === 25 ||
                    responseDay.data === 26 ||
                    responseDay.data === 27 ||
                    responseDay.data === 28 ||
                    responseDay.data === 29 ||
                    responseDay.data === 30
                  ) {
                    if (
                      responseFinal.data[X].Status === 1 ||
                      responseFinal.data[X].Status === 2 ||
                      responseFinal.data[X].Status === 4 ||
                      responseFinal.data[X].Status === 8
                    )
                      dataArray.push(responseFinal.data[X]);
                    if (
                      responseFinal.data[X].Status === 3 ||
                      responseFinal.data[X].Status === 5 ||
                      responseFinal.data[X].Status === 6 ||
                      responseFinal.data[X].Status === 7 ||
                      responseFinal.data[X].Status === 9 ||
                      responseFinal.data[X].Status === 10 ||
                      responseFinal.data[X].Status === 11 ||
                      responseFinal.data[X].Status === 12 ||
                      responseFinal.data[X].Status === 13 ||
                      responseFinal.data[X].Status === 14 ||
                      responseFinal.data[X].Status === 15
                    )
                      dataUnFlow.push(responseFinal.data[X]);
                  }
                }
                setServer(dataArray);
                if (responseDay.data !== 1 || responseDay.data !== 2)
                  setFlag(dataUnFlow);
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    GetData();
  }, []);
  const Hnadle = async (e) => {
    let DATA = null;
    let DAY = 0;
    if (Server === null || Server.length === 0) {
    } else {
      setShowStatement(true);
      if (Counter < Server.length) {
        if (e === "+") {
          if (Day === 1) DAY = 2;
          if (Day === 2) DAY = 3;
          if (Day === 3) {
            if (Server[Counter].Status === 2) DAY = 4;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 4) {
            if (Server[Counter].Status === 2) DAY = 5;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 5) {
            if (Server[Counter].Status === 2) DAY = 6;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 6) {
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 7) {
            if (Server[Counter].Status === 4) DAY = 8;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 8) {
            if (Server[Counter].Status === 4) DAY = 9;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 9) {
            if (Server[Counter].Status === 4) DAY = 10;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 10) {
            if (Server[Counter].Status === 4) DAY = 11;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 11) {
            if (Server[Counter].Status === 4) DAY = 12;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 12) {
            if (Server[Counter].Status === 4) DAY = 13;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 13) {
            if (Server[Counter].Status === 4) DAY = 14;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 14) {
            if (Server[Counter].Status === 4) DAY = 15;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day >= 15 && Day < 30) {
            if (Day === 15) if (Server[Counter].Status === 8) DAY = 16;
            if (Day === 16) if (Server[Counter].Status === 8) DAY = 17;
            if (Day === 17) if (Server[Counter].Status === 8) DAY = 18;
            if (Day === 18) if (Server[Counter].Status === 8) DAY = 19;
            if (Day === 19) if (Server[Counter].Status === 8) DAY = 20;
            if (Day === 20) if (Server[Counter].Status === 8) DAY = 21;
            if (Day === 21) if (Server[Counter].Status === 8) DAY = 22;
            if (Day === 22) if (Server[Counter].Status === 8) DAY = 23;
            if (Day === 23) if (Server[Counter].Status === 8) DAY = 24;
            if (Day === 24) if (Server[Counter].Status === 8) DAY = 25;
            if (Day === 25) if (Server[Counter].Status === 8) DAY = 26;
            if (Day === 26) if (Server[Counter].Status === 8) DAY = 27;
            if (Day === 27) if (Server[Counter].Status === 8) DAY = 28;
            if (Day === 28) if (Server[Counter].Status === 8) DAY = 29;
            if (Day === 29) if (Server[Counter].Status === 8) DAY = 30;
            if (Server[Counter].Status === 4) DAY = 15;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
          if (Day === 30) {
            if (Server[Counter].Status === 16) DAY = 31;
            if (Server[Counter].Status === 8) DAY = 30;
            if (Server[Counter].Status === 4) DAY = 15;
            if (Server[Counter].Status === 2) DAY = 7;
            if (Server[Counter].Status === 1) DAY = 3;
          }
        } else {
          DAY = 1;
        }
        if (Day === 1 || Day === 2) {
          DATA = {
            ID: Server[Counter].ID,
            Statement1: Server[Counter].Statement1,
            Statement2: Server[Counter].Statement2,
            Status: DAY,
          };
          Server[Counter] = DATA;
          setCounter(Counter + 1);
          if (Counter === Server.length - 1) {
            const updatePromises = Server.map((item) => {
              return AXIOS.put(`/FINAL/${item.ID}.json`, item);
            });
            Promise.all(updatePromises)
              .then((responses) => {
                AXIOS.put(`/DAYS.json`, Day + 1)
                  .then((responseDay) => {
                    if (responseDay.status === 200) {
                      console.log("Server update successful:", responses);
                      propse.setReviewPage(true);
                      propse.setGamePage(false);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.error("Error updating server:", error);
              });
          }
        }
        if (Day === 3 || Day === 4 || Day === 5 || Day === 6) {
          // console.log(Server);
          // console.log(Flag);
          for (let X in Flag) {
            DATA = {
              ID: Flag[X].ID,
              Statement1: Flag[X].Statement1,
              Statement2: Flag[X].Statement2,
              Status: 100003,
            };
            Flag[X] = DATA;
          }
          DATA = null;
          //console.log(Flag);
          DATA = {
            ID: Server[Counter].ID,
            Statement1: Server[Counter].Statement1,
            Statement2: Server[Counter].Statement2,
            Status: DAY,
          };
          Server[Counter] = DATA;
          setCounter(Counter + 1);
          if (Counter === Server.length - 1) {
            for (let X in Flag) {
              DATA = {
                ID: Flag[X].ID,
                Statement1: Flag[X].Statement1,
                Statement2: Flag[X].Statement2,
                Status: 2,
              };
              Flag[X] = DATA;
            }
            // console.log(Server);
            // console.log(Flag);
            const Concats = Server.concat(Flag);
            // console.log(Concats);
            const updatePromises = Concats.map((item) => {
              return AXIOS.put(`/FINAL/${item.ID}.json`, item);
            });
            Promise.all(updatePromises)
              .then((responses) => {
                AXIOS.put(`/DAYS.json`, Day + 1)
                  .then((responseDay) => {
                    if (responseDay.status === 200) {
                      console.log("Server update successful:", responses);
                      propse.setReviewPage(true);
                      propse.setGamePage(false);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.error("Error updating server:", error);
              });
          }
        }
        if (
          Day === 7 ||
          Day === 8 ||
          Day === 9 ||
          Day === 10 ||
          Day === 11 ||
          Day === 12 ||
          Day === 13 ||
          Day === 14
        ) {
          let CH = 0;
          for (let X in Flag) {
            if (Flag[X].Status === 3) CH = 100003;
            if (Flag[X].Status === 5) CH = 100005;
            if (Flag[X].Status === 6) CH = 100006;
            if (Flag[X].Status === 7) CH = 100007;
            DATA = {
              ID: Flag[X].ID,
              Statement1: Flag[X].Statement1,
              Statement2: Flag[X].Statement2,
              Status: CH,
            };
            if (
              Flag[X].Status === 3 ||
              Flag[X].Status === 5 ||
              Flag[X].Status === 6 ||
              Flag[X].Status === 7
            )
              Flag[X] = DATA;
          }
          DATA = null;
          //console.log(Flag);
          DATA = {
            ID: Server[Counter].ID,
            Statement1: Server[Counter].Statement1,
            Statement2: Server[Counter].Statement2,
            Status: DAY,
          };
          Server[Counter] = DATA;
          setCounter(Counter + 1);
          if (Counter === Server.length - 1) {
            for (let X in Flag) {
              let CH = 0;
              if (Flag[X].Status === 100003) CH = 2;
              if (Flag[X].Status === 100005) CH = 4;
              if (Flag[X].Status === 100006) CH = 5;
              if (Flag[X].Status === 100007) CH = 6;
              DATA = {
                ID: Flag[X].ID,
                Statement1: Flag[X].Statement1,
                Statement2: Flag[X].Statement2,
                Status: CH,
              };
              if (
                Flag[X].Status === 100003 ||
                Flag[X].Status === 100005 ||
                Flag[X].Status === 100006 ||
                Flag[X].Status === 100007
              )
                Flag[X] = DATA;
            }
            const Concats = Server.concat(Flag);
            console.log(Concats);
            const updatePromises = Concats.map((item) => {
              return AXIOS.put(`/FINAL/${item.ID}.json`, item);
            });
            Promise.all(updatePromises)
              .then((responses) => {
                AXIOS.put(`/DAYS.json`, Day + 1)
                  .then((responseDay) => {
                    if (responseDay.status === 200) {
                      console.log("Server update successful:", responses);
                      propse.setReviewPage(true);
                      propse.setGamePage(false);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.error("Error updating server:", error);
              });
          }
        }
        if (Day >= 15 && Day <= 30) {
          let CH = 0;
          for (let X in Flag) {
            if (Flag[X].Status === 3) CH = 100003;
            if (Flag[X].Status === 5) CH = 100005;
            if (Flag[X].Status === 6) CH = 100006;
            if (Flag[X].Status === 7) CH = 100007;
            if (Flag[X].Status === 9) CH = 100009;
            if (Flag[X].Status === 10) CH = 1000010;
            if (Flag[X].Status === 11) CH = 1000011;
            if (Flag[X].Status === 12) CH = 1000012;
            if (Flag[X].Status === 13) CH = 1000013;
            if (Flag[X].Status === 14) CH = 1000014;
            if (Flag[X].Status === 15) CH = 1000015;
            DATA = {
              ID: Flag[X].ID,
              Statement1: Flag[X].Statement1,
              Statement2: Flag[X].Statement2,
              Status: CH,
            };
            if (
              Flag[X].Status === 3 ||
              Flag[X].Status === 5 ||
              Flag[X].Status === 6 ||
              Flag[X].Status === 7 ||
              Flag[X].Status === 9 ||
              Flag[X].Status === 10 ||
              Flag[X].Status === 11 ||
              Flag[X].Status === 12 ||
              Flag[X].Status === 13 ||
              Flag[X].Status === 14 ||
              Flag[X].Status === 15
            )
              Flag[X] = DATA;
          }
          DATA = null;
          //console.log(Flag);
          DATA = {
            ID: Server[Counter].ID,
            Statement1: Server[Counter].Statement1,
            Statement2: Server[Counter].Statement2,
            Status: DAY,
          };
          Server[Counter] = DATA;
          setCounter(Counter + 1);
          if (Counter === Server.length - 1) {
            for (let X in Flag) {
              if (Flag[X].Status === 100003) CH = 2;
              if (Flag[X].Status === 100005) CH = 4;
              if (Flag[X].Status === 100006) CH = 5;
              if (Flag[X].Status === 100007) CH = 6;
              if (Flag[X].Status === 100009) CH = 8;
              if (Flag[X].Status === 1000010) CH = 9;
              if (Flag[X].Status === 1000011) CH = 10;
              if (Flag[X].Status === 1000012) CH = 11;
              if (Flag[X].Status === 1000013) CH = 12;
              if (Flag[X].Status === 1000014) CH = 13;
              if (Flag[X].Status === 1000015) CH = 14;
              DATA = {
                ID: Flag[X].ID,
                Statement1: Flag[X].Statement1,
                Statement2: Flag[X].Statement2,
                Status: CH,
              };
              if (
                Flag[X].Status === 100003 ||
                Flag[X].Status === 100005 ||
                Flag[X].Status === 100006 ||
                Flag[X].Status === 100007 ||
                Flag[X].Status === 100009 ||
                Flag[X].Status === 1000010 ||
                Flag[X].Status === 1000011 ||
                Flag[X].Status === 1000012 ||
                Flag[X].Status === 1000013 ||
                Flag[X].Status === 1000014 ||
                Flag[X].Status === 1000015
              )
                Flag[X] = DATA;
            }
            const Concats = Server.concat(Flag);
            console.log(Concats);
            const updatePromises = Concats.map((item) => {
              return AXIOS.put(`/FINAL/${item.ID}.json`, item);
            });
            Promise.all(updatePromises)
              .then((responses) => {
                AXIOS.put(`/DAYS.json`, Day + 1)
                  .then((responseDay) => {
                    if (responseDay.status === 200) {
                      console.log("Server update successful:", responses);
                      propse.setReviewPage(true);
                      propse.setGamePage(false);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.error("Error updating server:", error);
              });
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      {Loading ? (
        "Loading ..."
      ) : (
        <React.Fragment>
          <Box className="ID">
            {Server === null || Server.length === 0 ? (
              <span className="Nothing">- Nothing -</span>
            ) : (
              `ID :${Counter + 1}FROM${Server.length}`
            )}
          </Box>
          <Box
            className="Text"
            onClick={() => setShowStatement(!ShowStatement)}
            sx={{
              direction: ShowStatement ? "rtl" : "ltr",
              fontFamily: ShowStatement ? "FA-EN05" : "Outfit",
              fontSize: ShowStatement ? "0.85rem" : "0.95rem",
            }}
          >
            {Server === null || Server.length === 0 ? (
              <span className="Nothing">- Nothing -</span>
            ) : ShowStatement ? (
              Counter < Server.length ? (
                Server[Counter].Statement2
              ) : (
                Server[Counter - 1].Statement2
              )
            ) : Counter < Server.length ? (
              Server[Counter].Statement1
            ) : (
              Server[Counter - 1].Statement1
            )}
          </Box>
          <Box className="Extra">
            <Box>
              <ArrowBackIcon
                onMouseEnter={() => setInfo("Back To Review")}
                onMouseLeave={() => setInfo("")}
                onClick={HnadleBack}
              />
              <DoneAllIcon
                onMouseEnter={() => setInfo("Correct Answer !")}
                onMouseLeave={() => setInfo("")}
                onClick={() => Hnadle("+")}
              />
              <CloseIcon
                onMouseEnter={() => setInfo("Incorrect Answer !")}
                onMouseLeave={() => setInfo("")}
                onClick={() => Hnadle("-")}
              />
            </Box>
            <Box>{Info}</Box>
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Game;
