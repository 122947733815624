import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import AXIOS from "../../Axios";

const Grammer = () => {
  const [GADD, setGADD] = useState(false);
  const [Server, setServer] = useState([]);
  const [Counter, setCounter] = useState(0);
  const [Statement, setStatement] = useState("");
  const [Grammers, setGrammers] = useState("");
  const GetData = async () => {
    await AXIOS.get("/GRAMMER.json")
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          if (response.data === null) setCounter(0);
          else setCounter(response.data.length);
          setServer(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    GetData();
  }, []);
  const SAVE = async () => {
    const DATA = {
      Statement: Statement,
      Grammers: Grammers,
    };
    if (Statement.length !== 0 && Grammers.length !== 0) {
      await AXIOS.put(`/GRAMMER/${Counter}.json`, DATA)
        .then((response) => {
          if (response.status === 200) {
            GetData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <Box className="GrammerBox">
      <Box className="Header">
        <Box onClick={() => setGADD(false)}>
          <span>SHOW</span>
        </Box>
        <Box onClick={() => setGADD(true)}>
          <span>ADD</span>
        </Box>
      </Box>
      {!GADD ? (
        Server !== null ? (
          Server.map((option, index) => (
            <Box key={index} className="Show">
              <Box className="Show1">
                <span>{option.Statement}</span>
              </Box>
              <Box className="LINE"></Box>
              <Box className="Show2">
                {option.Grammers.split("\n").map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </Box>
            </Box>
          ))
        ) : (
          ""
        )
      ) : (
        <Box className="Record">
          <textarea
            id="Statement1"
            className="TextAreaGrammer1"
            value={Statement}
            onChange={(e) => setStatement(e.target.value)}
            placeholder="Current Sentence"
          ></textarea>
          <textarea
            id="Statement1"
            className="TextAreaGrammer2"
            value={Grammers}
            onChange={(e) => setGrammers(e.target.value)}
            placeholder="it's Grammer"
          ></textarea>
          <Box className="BTN" onClick={SAVE}>
            Save Current Grammer
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Grammer;
