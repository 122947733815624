import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import AXIOS from "../../Axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CloudQueueOutlinedIcon from "@mui/icons-material/CloudQueueOutlined";

const Old = (propse) => {
  const [Loading, setLoading] = useState(true);
  const [Server, setServer] = useState([]);
  const [Server2, setServer2] = useState([]);
  const [Counter, setCounter] = useState(0);
  const [Info, setInfo] = useState("");
  const [Add, setAdd] = useState(false);
  const [Statement1, setStatement1] = useState("");

  const HnadleBack = () => {
    propse.setReviewPage(true);
    propse.setOldPage(false);
  };
  const GetData = async () => {
    await AXIOS.get("/EN2.json")
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          console.log(response.data);
          setServer2(response.data);
          for (let X in response.data) {
            if (response.data[X] !== null) {
              setServer(response.data[X]);
              setCounter(X);
              break;
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    GetData();
  }, []);
  const HandleMove = async () => {
    propse.setReviewPage(false);
    propse.setOldPage(false);
    propse.setCreatePage(true);
    propse.setMoveFC(Server.Statement2);
    await AXIOS.delete(`/EN2/${Counter}.json`)
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const HandleAdd = async () => {
    const DATA = {
      ID: Server2.length,
      Statement1: Statement1,
      Statement2: "",
      Status: 0,
    };
    if (Statement1.length !== 0) {
      await AXIOS.put(`/EN2/${Server2.length}.json`, DATA)
        .then((response) => {
          if (response.status === 200) {
            setStatement1("");
            setLoading(true);
            GetData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <React.Fragment>
      {Loading ? (
        "Loading ..."
      ) : (
        <React.Fragment>
          <Box className="ID">
            ID :
            {Server2 === null || Server2.length === 0
              ? "0FROM0"
              : `${Server2.length}`}
          </Box>
          <Box className="Text">
            {Add ? (
              <textarea
                id="Statement1"
                className="TextArea"
                style={{ direction: "ltr" }}
                value={Statement1}
                onChange={(e) => setStatement1(e.target.value)}
              ></textarea>
            ) : (
              Server.Statement2
            )}
          </Box>
          <Box className="Extra">
            {Add ? (
              <React.Fragment>
                <Box>
                  <ArrowBackIcon
                    onMouseEnter={() => setInfo("Back To Review")}
                    onMouseLeave={() => setInfo("")}
                    onClick={() => setAdd(false)}
                  />
                  <CloudQueueOutlinedIcon
                    onMouseEnter={() => setInfo("Back To Review")}
                    onMouseLeave={() => setInfo("")}
                    onClick={HandleAdd}
                  />
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box>
                  <ArrowBackIcon
                    onMouseEnter={() => setInfo("Back To Review")}
                    onMouseLeave={() => setInfo("")}
                    onClick={HnadleBack}
                  />
                  <MoveUpIcon
                    onMouseEnter={() => setInfo("Move Current FC")}
                    onMouseLeave={() => setInfo("")}
                    onClick={HandleMove}
                  />
                  <PostAddIcon
                    onMouseEnter={() => setInfo("Add FC")}
                    onMouseLeave={() => setInfo("")}
                    onClick={() => setAdd(true)}
                  />
                </Box>
                <Box>{Info}</Box>
              </React.Fragment>
            )}
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Old;
