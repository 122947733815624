import React, { useEffect, useState } from "react";
import CloudQueueOutlinedIcon from "@mui/icons-material/CloudQueueOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AXIOS from "../../Axios";
import { Box } from "@mui/material";

const Change = (propse) => {
  const [Loading, setLoading] = useState(true);
  const [Statement1, setStatement1] = useState("");
  const [Statement2, setStatement2] = useState("");
  const [Info, setInfo] = useState("");
  const [Server, setServer] = useState([]);
  const HnadleBack = () => {
    propse.setReviewPage(true);
    propse.setChangePage(false);
  };
  const onSubmit = async () => {
    const DATA = {
      ID: propse.FCChange.ID,
      Statement1: Statement1,
      Statement2: Statement2,
      Status: propse.FCChange.Status,
    };
    await AXIOS.put(`/FINAL/${propse.FCChange.ID}.json`, DATA)
      .then((response) => {
        if (response.status === 200) {
          propse.setReviewPage(true);
          propse.setChangePage(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetData = async () => {
    await AXIOS.get("/FINAL.json")
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setServer(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setStatement1(propse.FCChange.Statement1);
    setStatement2(propse.FCChange.Statement2);
    GetData();
  }, [propse.FCChange]);

  return (
    <React.Fragment>
      {Loading ? (
        "Loading ..."
      ) : (
        <React.Fragment>
          <Box className="ID">
            ID :{`${propse.FCChange.ID + 1}FROM${Server.length}`}
          </Box>
          <Box className="Text_TextArea">
            <textarea
              id="Statement1"
              className="TextArea"
              style={{ direction: "ltr" }}
              value={Statement1}
              onChange={(e) => setStatement1(e.target.value)}
            >
              {Statement1}
            </textarea>
            <Box sx={{ color: "rgb(0, 0, 0,0.3)", fontFamily: "RA-EN04" }}>
              -----------------------------------
            </Box>
            <textarea
              id="Statement2"
              className="TextArea"
              style={{ direction: "rtl" }}
              value={Statement2}
              onChange={(e) => setStatement2(e.target.value)}
            >
              {Statement2}
            </textarea>
          </Box>
          <Box className="Extra">
            <Box>
              <ArrowBackIcon
                onMouseEnter={() => setInfo("Back to review")}
                onMouseLeave={() => setInfo("")}
                onClick={HnadleBack}
              />
              <CloudQueueOutlinedIcon
                onMouseEnter={() => setInfo("Change Current FC")}
                onMouseLeave={() => setInfo("")}
                onClick={onSubmit}
              />
            </Box>
            <Box>{Info}</Box>
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Change;
